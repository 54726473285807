<template>
  <div class="container-fluid" :style="style">
    <div class="L1"></div>
    <div class="row L2">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-6 intro">
            <h1>关于本站</h1>
            <p>本站软件都是我根据拍摄经历，结合实际使用情况设计开发的</p>
            <p>希望能方便广大摄影师，通过让流程的规范化来提高工作效率</p>
            <p>软件可能有收费，但是我坚持：一个纯本地的软件不应该包含任何订阅功能，不应该搞低价购买+内购的模式，更不应该搞购买之后只能更新一年的模式。所以我的软件要么是免费的，要么是一次购买永久使用的模式。</p>
            <p>如果有什么想法欢迎点击右下角联系我</p>
          </div>
          <div class="col-xs-12 col-md-6 img">
            <img src="@/assets/img/about/2.jpg" alt="" srcset="">
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>
<script>
export default {
  name: 'About',
  props: {
    
  },
  computed:{
    style(){
      return {
        background:`url(${require("@/assets/img/about/2.jpg")}) no-repeat right`,
        backgroundSize: 'cover'
      }
    },
    style2(){
      return {
        background:`url(${require("@/assets/img/about/2.jpg")}) no-repeat right`,
        backgroundSize: 'contain'
      }
    },
  },
  data(){
    return {
      publicPath: process.env.BASE_URL
    }
  },
  mounted(){
    
  },
  methods:{
    download(link){
      var a = document.createElement('a');
      a.href = link;//下载文件地址
      a.click(); 
    },
  }

}
</script>
<style lang="less" scoped>
	.flex{
		display: flex;
		align-items: center;
		justify-content: flex-start;	
	}
  .container-fluid{
    background-color: #eee;
    width: 100%;
    padding: 0;
    margin: 0;
    .row{
      margin: 0;
      padding: 0;
    }
    .L1{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      backdrop-filter: blur(40px);
      background-color: rgba(0,0,0,0.3);
    }
    .L2{
      color: #fff;
      padding-top: 100px;
      min-height: 100vh;
      margin-top: 20vh;
      background-size: cover;
      padding-bottom: 40px;
      .intro{
        font-weight: 300;
        h1{
          font-size: 80px;
          font-weight: 300;
        }
        p{
          text-shadow: 0 0 10px rgba(0,0,0,0.7);
        }
      }
      .img{
        img{
          max-width: 400px;
          width: 100%;
        }
      }
    }
  }
</style>